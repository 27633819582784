@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Trirong:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=UnifrakturMaguntia&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Serif:ital,opsz,wght@0,8..144,100..900;1,8..144,100..900&display=swap');

@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

/* Header font */
.unifrakturmaguntia-regular {
  font-family: "UnifrakturMaguntia", cursive;
  font-weight: 600;
  font-style: normal;
}

.abril-fatface-regular {
  font-family: "Abril Fatface", serif;
  font-weight: 400;
  font-style: normal;
}

body {
    scroll-behavior: smooth;
    font-family: "Roboto Serif", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


.hamburger:active {
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  transition-duration: 20ms;
  transition-timing-function: ease-in;
}

.hoveranimation {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
        0 2px 4px -1px rgba(0, 0, 0, 0.06);
    transition: all 200ms ease 0s;
}

.hoveranimation:hover {
    transform: translate(1px, -2px);
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
}



.mouseAnimation {
    animation: mouseanimation 12s infinite;
}
.bumping {
    animation: bumping 1s infinite;
}

.fade-in {
    animation: fadeIn 1s;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes mouseanimation {
  0%, 100% { transform: translate(0%); }
  
  20% { 
      transform: translate(0%); 
    }
  21% { 
      transform: translate(35px, -10px); 
    }
  22% { 
      transform: translate(-35px, 10px); 
    }
  23% { 
      transform: translate(35px, -10px); 
    }
  24% { 
      transform: translate(-35px, 10px); 
    }
  25% { 
      transform: translate(35px, -10px); 
    }
  26% { 
      transform: translate(-35px, 10px); 
    }
  27% { 
      transform: translate(35px, -10px); 
    }
  28% { 
      transform: translate(-35px, 10px); 
    }
  29% { 
      transform: translate(35px, -10px); 
    }
  30% { 
      transform: translate(0%); 
    }
} 

@keyframes bumping {
    0%, 100% {
        transform: translate(0%); 
    }
    50% {
        transform: translateY(15px); 
    }

}









/*   Burger Menu  */

.menu-btn {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  transition: all .5s ease-in-out;
  margin-right: 1.5rem;
}
.menu-btn__burger {
  width: 25px;
  height: 3px;
  background: #fff;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}
.menu-btn__burger::before,
.menu-btn__burger::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background: #fff;
  border-radius: 5px;
  transition: all .5s ease-in-out;
}
.menu-btn__burger::before {
  transform: translateY(-8px);
}
.menu-btn__burger::after {
  transform: translateY(8px);
}
/* ANIMATION */
.menu-btn.open .menu-btn__burger {
  transform: translateX(-50px);
  background: transparent;
  box-shadow: none;
}
.menu-btn.open .menu-btn__burger::before {
  transform: rotate(45deg) translate(35px, -35px);
}
.menu-btn.open .menu-btn__burger::after {
  transform: rotate(-45deg) translate(35px, 35px);
}
